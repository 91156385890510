import React, { Fragment, useEffect, useState } from "react";
import "../../Style/Homepage.css";

import testImage from "../../LottieJson/herobanner.jpg";
import { Container } from "react-bootstrap";

const Herosection = () => {
  const phrases = ["Health", "Insurance"];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      const nextIndex = (currentIndex + 1) % phrases.length;
      setCurrentIndex(nextIndex);
    }, 3000);

    return () => clearTimeout(timer);
  }, [currentIndex]);

  return (
    <Fragment>
      <div className="">
        <section className="bg-[#FFC656] p-0">
          <div className="flex flex-col sm:flex-row-reverse justify-between w-full">
            <div className="w-full sm:w-[50%] border-[#FFC656]">
              <img
                src={testImage}
                className="h-full w-full object-cover sm:object-cover"
                alt="heroimg"
              />
            </div>
            <div className="w-full sm:w-[50%] ">
              <Container>
                <div className="flex flex-col items-left my-6 sm:my-20 ml-4 sm:ml-10 md:ml-16 lg:ml-40 px-2 sm:px-20 ">
                  <div className="heading-container relative ">
                    <h1
                      className="font-medium text__78 mb-4 md:text-left"
                      style={{
                        fontFamily: "Nayuki",
                        fontWeight: "300",
                      }}
                    >
                      {phrases.map((item, index) => (
                        <span
                          key={index}
                          className={`animation phrasetext text__60 ${
                            index === currentIndex
                              ? "active"
                              : "inactive"
                          }`}
                          style={{
                            fontFamily: "Outfit",
                            color:
                              index % 2 === 0 ? "#000000" : "#000000",
                          }}
                        >
                          {item}
                        </span>
                      ))}
                      <br />
                      <span className="sabke-liye">
                        {" "}
                        Sabke Liye.{" "}
                      </span>
                    </h1>
                  </div>
                  <h4
                    className="text__18 md:leading-7 mb-10 md:text-left"
                    style={{ fontFamily: "Outfit" }}
                  >
                    Your health, our priority. Access interest-free
                    credit <br /> for your health and insurance needs.
                    <br />
                    Secure your future today and pay at your
                    convenience.
                  </h4>

                  <div className="border-2 rounded-lg w-fit flex gap-4 p-3">
                    <div>
                      <img
                        src="https://eazr.s3.ap-south-1.amazonaws.com/1726209902759-onelinkto-eazr.png"
                        alt=""
                        className="h-[80px] sm:h-[100px] w-[80px] sm:w-[100px]"
                      />
                    </div>
                    <div>
                      <h3 className="text-xl sm:text-2xl font-bold max-w-[6rem] m-auto flex justify-center items-center h-full">
                        Get the Eazr app
                      </h3>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default Herosection;
